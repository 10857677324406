import { Link, graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import Content from '../Content'
import alisiLogo from '../../assets/images/alisi_logo.png'
import './SellersHome.scss'

const SellersHomePage = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query sellersHomeLinks {
      externalLinks {
        links {
          id
          link
          category
          name
          image
        }
      }
    }
  `)

  return (
    <>
      <Content>
        <h1>Sellers Home</h1>

        <div className="links-container">
          {data.externalLinks.links
            .filter((x: any) => x.name.includes('Seller') || x.name.includes('seller'))
            .map((link: any, index: number) => (
              <Link key={link.name} to={link.link} target="_blank">
                <div className="links-card">
                  {link.image ? (
                    <img className="card-image" src={link.image} alt={link.name} />
                  ) : (
                    <img className="fallback-image" src={alisiLogo} alt={link.name} />
                  )}
                  <p>{link.name}</p>
                </div>
              </Link>
            ))}
        </div>
      </Content>
    </>
  )
}

export default SellersHomePage
