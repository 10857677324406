import React, { ReactElement, useState } from 'react'
import SellersHome from '../../components/Layout/SellersHome'

import SEO from '../../components/SEO/SEO'

import heroImage from '../../assets/images/sellers-home/sellers-home.png'
import SellersHomePage from '../../components/SellersHomePage'

import { graphql, useStaticQuery } from 'gatsby'

const ContactUsPage = (): ReactElement => {
  const sellersHomeHeroBanner = useStaticQuery(graphql`
    query {
      heroBanner(title: { eq: "Sellers Home" }) {
        image
      }
    }
  `)

  return (
    <>
      <SellersHome heroImage={sellersHomeHeroBanner.heroBanner ? sellersHomeHeroBanner.heroBanner.image : ''}>
        <SEO title="SELLERS HOME - ALISI | Ayala Land" />
        <SellersHomePage />
      </SellersHome>
    </>
  )
}

export default ContactUsPage
