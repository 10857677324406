import React, { ReactElement, SetStateAction } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import './Layout.scss'
import './Animations.scss'
import Hero from '../AboutUsPage/Hero'
import ContentContainer from '../ContentContainer'

type LayoutPropTypes = {
  children?: string | Element | ReactElement | boolean | (string | Element | ReactElement | boolean)[]
  heroImage?: string
  heroTitle?: string
}

const SellersHome = ({ children, heroImage, heroTitle }: LayoutPropTypes): ReactElement => (
  <>
    <Header />
    <Hero heroImage={heroImage} heroTitle={heroTitle} />
    <ContentContainer>{children}</ContentContainer>
    <Footer />
  </>
)

export default SellersHome
